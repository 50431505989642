import * as React from "react"
import FadeIn from "react-fade-in/lib/FadeIn"
import PageLayout from "../../components/page-layout"
import ExternalLink from "../../components/external-link"
import Navbar from "../../components/navbar"
import HoverImage from "react-hover-image"

import Grac4Img from "../../images/grac-4.jpeg"

import "./style.css"

const AboutPage = () => {
  return (
    <PageLayout title="about | grac">
      <body id="home-page" className="page">
        <div className="center-container">
          <Navbar />
          <FadeIn>
            <h1 className="page-title">
              <span style={{ color: "var(--pink)" }}>A</span>
              <span style={{ color: "var(--yellow)" }}>B</span>
              <span style={{ color: "var(--green)" }}>O</span>
              <span style={{ color: "var(--lightblue)" }}>U</span>
              <span style={{ color: "var(--purple)" }}>T</span>
            </h1>

            {/* <div className="block-35">
            <img
              src={Grac4Img}
              style={{
                width: "88%",
                borderRadius: "10px",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
            <HoverImage
              src={Grac4Img}
              style={{
                width: "86%",
                borderRadius: "10px",
                marginBottom: "12px",
              }}
            />
          </div> */}

            <div className="page-block">
              <h2>hi, nice to meet you :-)</h2>
              {/* Nice to meet you!  */}
              <p>
                I'm Grace, a Chinese-American born and raised in the Bay Area of
                California. I recently graduated from UPenn with degrees in
                Computer Science and Business Analytics, and I'm currently
                working in Chicago as a trader.
                <br />
                <br />
                Some of my interests include: playing video &amp; card games
                (especially Canadian fish, 掼蛋, 拖拉机), the handmade web, and
                playing / discovering new music (recs always appreciated!).
                <br />
                <br />
                <small>
                  <strong>find me elsewhere</strong>: <ExternalLink href="https://instagram.com/gracjj">
                    {/* 📷 */}
                    instagram
                  </ExternalLink>
                  &nbsp;/&nbsp;
                  <ExternalLink href="https://open.spotify.com/user/gjiang00">
                    {/* 🎵 */}
                    spotify
                  </ExternalLink>
                  &nbsp;/&nbsp;
                  <ExternalLink href="https://github.com/gracejiang">
                    {/* 👩🏻‍💻 */}
                    github
                  </ExternalLink>
                  &nbsp;/&nbsp;
                  <ExternalLink href="mailto:gracj@seas.upenn.edu">
                    {/* 📩 */}
                    email
                  </ExternalLink>
                </small>
                <br />
                <br />
                <hr />
              </p>
            </div>

            <div className="page-block" style={{ marginTop: "40px" }}>
              <div className="block-47">
                <h3>currently listening to</h3>
                <br />
                <iframe
                  src="https://open.spotify.com/embed/playlist/5EWwYqTrwIp6UqcuRzSGAg?utm_source=generator"
                  frameBorder="0"
                  allowfullscreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid #ccc;",
                    minHeight: "380px",
                  }}
                ></iframe>
              </div>

              <div className="block-6"></div>

              <div className="block-47">
                <h3>book shelf</h3>
                <br />
                <iframe
                  className="airtable-embed"
                  src="https://airtable.com/embed/shr2ZkWvszoYqVgjj?backgroundColor=teal&layout=card"
                  frameborder="0"
                  onmousewheel=""
                  style={{
                    border: "1px solid #ccc;",
                    width: "100%",
                    borderRadius: "10px",
                    minHeight: "380px",
                  }}
                ></iframe>
              </div>
            </div>
          </FadeIn>
        </div>
        <div style={{ height: "50px" }} />
      </body>
    </PageLayout>
  )
}

export default AboutPage
