import * as React from "react";
import { Helmet } from "react-helmet";

import FavIcon from "../images/koya.png"

import "../styles/global.css"

class PageLayout extends React.Component {
  render() {
    return (
      <html lang="en">
        <head>
          <Helmet>
            <title>{this.props.title}</title>
            <link rel="icon" href={FavIcon} />
          </Helmet>
        </head>
        <img style={{display: "none"}} src="https://hitwebcounter.com/counter/counter.php?page=8159909&style=0007&nbdigits=6&type=ip&initCount=0" />
        <img style={{display: "none"}} src="https://hitwebcounter.com/counter/counter.php?page=7994736&style=0007&nbdigits=6&type=ip&initCount=0"/>
        {this.props.children}
      </html>
    );
  }
}

export default PageLayout;