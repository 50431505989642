import * as React from "react";
import { Link } from "gatsby";

import "./style.css";
import ExternalLink from "../external-link";

export default function Navbar() {
  return (
    <div>
      <ul className="navbar-custom">
        <li className="navbar-left"><Link to="/" style={{fontWeight: "600", fontSize: "180%", textTransform: "none", fontFamily: "IBM Plex Mono, Karla, sans-serif;"}} activeStyle={{color: "black"}}>:o)</Link></li>
        {/* <li className="navbar-right"><Link to="/notes/" activeStyle={{color: "black"}}>notes</Link></li> */}
        <li className="navbar-right"><Link to="/projects/" activeStyle={{color: "black"}}>projects</Link></li>
        {/* <li className="navbar-right"><Link to="/work/" activeStyle={{color: "black"}}>work</Link></li> */}
        <li className="navbar-right"><ExternalLink href='https://www.linkedin.com/in/gracj'>work</ExternalLink></li>
        <li className="navbar-right"><Link to="/about/" activeStyle={{color: "black"}} >about</Link></li>
      </ul>
    </div>
  );
}
